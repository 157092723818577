import React from 'react'
import Navbar from '../components/navbar'
import ServicesOne from '../components/ServiceFour/secone'
import Sectiontwo from '../components/ServiceFour/sectwo'
import Sectionthree from '../components/ServiceFour/secthree'
import Footer from '../components/footer'

export default function serviceone() {
  return (
    <div>
        <Navbar/>
        <ServicesOne/>
        {/* <Sectiontwo/> */}
        {/* <Sectionthree/> */}
        <Footer/>
    </div>
  )
}
